<template>
  <v-list-item v-if="listItem"
               color="primary"
               :to="value.to"
               exact
               :class="listItemClass"
               @click="handleActionClick()"
               :disabled="disabled">
    <v-list-item-title v-if="right">
      {{ value.label }}
    </v-list-item-title>
    <v-icon v-if="value.icon"
            :left="!right" :right="right"
            v-bind="iconAttrs"
            :class="{'pl-8': right, 'pr-2': !right, 'icon-accent': true}">{{ value.icon }}
    </v-icon>
    <v-list-item-title v-if="!right">
      {{ value.label }}
    </v-list-item-title>
  </v-list-item>
  <atom-button v-else
               :to="value.to"
               exact
               v-bind="$attrs"
               :class="buttonClass"
               @click="handleActionClick()"
               :disabled="disabled">
    <span v-if="right">{{ value.label }}</span>
    <v-icon v-if="value.icon"
            :left="!right" :right="right"
            v-bind="iconAttrs"
            class="icon-accent">
      {{ value.icon }}
    </v-icon>
    <span v-if="!right">{{ value.label }}</span>
  </atom-button>
</template>

<style scoped lang="scss">
.atom-project-action-button {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, unref } from 'vue'
import AtomButton from '../../../../components/atoms/AtomButton.vue'
import type { ProjectAction } from '../../use/project-actions'

export default defineComponent({
  name: 'AtomProjectActionButton',
  components: { AtomButton },
  props: {
    value: {
      type: Object as PropType<ProjectAction>,
      required: true
    },
    listItem: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const handleActionClick = async () => {
      await props.value.perform?.()
    }

    const disabled = computed(() => {
      return unref(props.value.disabled)
    })

    const iconAttrs = computed(() => {
      return unref(props.value.iconAttrs)
    })

    const buttonClass = computed(() => {
      return unref(props.value.class)
    })

    const listItemClass = computed(() => {
      return unref(props.value.class)
    })

    return { handleActionClick, disabled, iconAttrs, buttonClass, listItemClass }
  }
})
</script>
