<template>
  <v-card class="molecule-create-project-card" v-bind="$attrs">
    <v-card-text :class="{'pa-12 card': !$vuetify.breakpoint.xs, 'pa-6': $vuetify.breakpoint.xs}">
      <div class="d-flex align-center justify-between"
           :class="{'flex-column-reverse align-center': $vuetify.breakpoint.xs, 'flex-row': !$vuetify.breakpoint.xs}">
        <div class="d-flex flex-column" :class="{'align-center text-center': $vuetify.breakpoint.xs}">
          <div>
            <h5 v-html="$tl(
                  'molecule-create-project-card.title',
                  `Obtenez le score environnemental <strong>Bat'impact®</strong> de votre projet d'architecture d'intérieur en quelques minutes !`
                )">
            </h5>
          </div>
          <div class="pt-6">
            <span class="body-1 text-lightgray">{{
                $tl(
                  'molecule-create-project-card.body',
                  'Et améliorez vos pratiques d\'éco-conception grâce à des conseils sur-mesure.'
                )
              }}</span>
          </div>
          <v-spacer></v-spacer>
          <slot name="cta"></slot>
        </div>
        <div :class="{'pl-8': !$vuetify.breakpoint.xs, 'pb-6': $vuetify.breakpoint.xs}">
          <img style="width: 193px; height: 238px"
               src="../../assets/carbon-saver-user-holding-compass.svg"
               alt="Carbon Saver">
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MoleculeCreateProjectCard'
})
</script>
<style lang="scss" scoped>
.justify-between {
  justify-content: space-between;
}
.text-lightgray {
  color: #edf4ec;
  opacity: 0.5;
}
</style>
