<template>
  <div class="organism-home-projects">
    <v-row>
      <v-col>
        <div class="d-flex align-center justify-between">
          <div class="flex-grow-10">
            <h3>{{ $tl('organism-home-projects.current-projects', 'Projets récents') }}</h3>
          </div>
          <div class="flex-grow-0">
            <v-btn :to="{name: 'projects'}" text color="primary">
              {{ $tl('organism-home-projects.all-projects', 'tous les projets') }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="body-1">
          {{ $tl('organism-home-projects.current-projects-subtitle', 'Retrouvez ici les derniers projets modifiés.') }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="licenseType === 'free'">
      <v-col>
        <atom-subscribe-alert>
          {{
            $tl('organism-home-projects.project-limit', 'vous êtes actuellement limité à 2 projets. vous avez besoin de plus ?')
          }}
        </atom-subscribe-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <molecule-project-list-cards :max-count="4"></molecule-project-list-cards>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.organism-home-projects {
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeProjectListCards from '../molecules/MoleculeProjectListCards.vue'
import AtomSubscribeAlert from '../../../../components/atoms/AtomSubscribeAlert.vue'
import { useLicense } from '../../../../use/license'

export default defineComponent({
  name: 'OrganismHomeProjects',
  components: { AtomSubscribeAlert, MoleculeProjectListCards },
  setup: () => {
    const { licenseType } = useLicense()

    return { licenseType }
  }
})
</script>
