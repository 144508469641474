<template>
  <v-navigation-drawer class="molecule-app-navigation-drawer"
                       app
                       v-model="open"
                       :color="dark ? 'cs-green-forest' : 'cs-soft-white'"
                       :temporary="mobile"
                       :permanent="!mobile && open"
                       :dark="dark"
                       v-bind="$attrs"
                       v-on="$listeners">
    <div class="d-flex flex-column fill-height">
      <atom-carbon-saver-logo class="px-2 py-12" v-if="carbonSaverLogo" :dark="dark"></atom-carbon-saver-logo>
      <slot></slot>
      <div class="justify-center" v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
      <v-spacer></v-spacer>
      <div v-if="$slots.footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import AtomCarbonSaverLogo from '../atoms/AtomCarbonSaverLogo.vue'

export default defineComponent({
  name: 'MoleculeAppNavigationDrawer',
  components: { AtomCarbonSaverLogo },
  props: {
    value: {
      type: Boolean as PropType<boolean>
    },
    mobile: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    dark: {
      type: Boolean as PropType<boolean>
    },
    carbonSaverLogo: {
      type: Boolean as PropType<boolean>
    }
  },
  setup (props, { emit }) {
    const valueData = useVModel(props, 'value', emit, { eventName: 'input', defaultValue: props.value })

    const open = computed<boolean>({
      get: () => {
        return props.mobile ? valueData.value === true : true
      },
      set: (value) => {
        if (props.mobile) {
          valueData.value = value
        }
      }
    })

    return { open }
  }
})
</script>
<style lang="scss">
.molecule-app-navigation-drawer {
}
</style>
