<template>
  <div class="molecule-project-list-cards">
    <v-row>
      <template v-if="listProjects.isLoading.value">
        <v-col cols="12" md="6" v-for="x in 4" :key="'loading-' + x">
          <v-skeleton-loader
            width="100%"
            type="image, actions">
          </v-skeleton-loader>
        </v-col>
      </template>
      <template v-else-if="projects.length > 0">
        <v-col cols="12" md="6" v-for="project of projects" :key="project.id">
          <molecule-project-card :value="project"></molecule-project-card>
        </v-col>
      </template>
      <v-col v-else>
        <span class="text-dark">
          {{
            $tl('molecule-project-list-cards.no-project', 'Il n\'y a aucun projet dans votre espace utilisateur. créez un nouveau projet pour remplir cet espace.')
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.text-dark {
  color: #0f231f;
  font-size: 16px;
}
</style>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { injectRequired } from '../../../../di'
import MoleculeProjectCard from './MoleculeProjectCard.vue'
import { ProjectsState } from '../../states/projects'

export default defineComponent({
  name: 'MoleculeProjectListCards',
  components: { MoleculeProjectCard },
  props: {
    maxCount: {
      type: Number
    }
  },
  setup: (props) => {
    const { listProjects, projects: allProjects } = injectRequired(ProjectsState).use
    listProjects.run()

    const projects = computed(() => {
      if (!allProjects.value) {
        return []
      }

      return props.maxCount !== undefined ? allProjects.value.slice(0, props.maxCount) : allProjects.value
    })

    return {
      listProjects,
      projects
    }
  }
})
</script>
